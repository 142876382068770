body{
  font-family: 'Karla', sans-serif;
}

h1 {
  font-family: 'Barlow', sans-serif;
  color: #11168E;
  font-size: 2.5em;
  margin: 0;
}

h2{
  font-family: 'Karla', sans-serif;
  font-size: 2.1em;
  margin: 0;
}


header {
  max-width: 1400px;
  margin: 4em auto 0 auto;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  header {
    grid-template-columns: 1fr;
    grid-template-rows: 170px auto auto;
  }

  header .main-title {
    grid-area: 1 / 1 / 2 / 2;
  }

  header img#header-img { 
    grid-area: 2 / 1 / 3 / 2;
  }

  header .header-description {
    grid-area: 3 / 1 / 4 / 2;
  }

  .details {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .details .hire-instructions {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 2.5em 2.5em 2.5em;
    font-size: 1.1em;
  }

  .details .hire-instructions p{
    margin: 0.9em 0;
    font-size: 1.1em;
  }

  .details .pricing {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 2.5em 2.5em 2.5em;
    font-size: 1.1em;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1100px) {
  header {
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-rows: 170px 1fr;
  }

  header .main-title {
    grid-area: 1 / 2 / 2 / 3;
  }

  header img#header-img { 
    grid-area: 1 / 1 / 3 / 2;
  }

  header .header-description {
    grid-area: 2 / 2 / 3 / 3;
  }
}

@media screen and (min-width: 1100px){
  .details .hire-instructions {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0 2.5em 2.5em 2.5em;
    font-size: 1.2em;
  }

  .details .hire-instructions p{
    margin: 0.9em 0;
    font-size: 1.15em;
  }

  .details .pricing {
    grid-area: 1 / 2 / 2 / 3;
    padding: 0 2.5em 2.5em 2.5em;
    font-size: 1.2em;
    background: #1c1c1c;
  }
}

@media screen and (min-width: 1200px) {
  header {
    grid-template-columns: 0.7fr 0.3fr;
    grid-template-rows: 170px 1fr;
  }

  header .main-title {
    grid-area: 1 / 2 / 2 / 3;
  }

  header img#header-img { 
    grid-area: 1 / 1 / 3 / 2;
  }

  header .header-description {
    grid-area: 2 / 2 / 3 / 3;
  }
}

header img#header-img { 
  object-fit: cover;
  width: 100%;
  max-height: 500px;
}

header .main-title {
  padding: 40px 0 40px 0px;
  text-align: center;
}

header h2{
  color: #7D7EAA;
}


header .header-description {
  background: #933632;
  color: #fff;
  padding: 2em;
  font-family: 'Karla', sans-serif;
  font-size: 1.1em;
}

.images-list {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background: #000;
  max-width: 1700px;
  max-height: 300px;
  padding: 10px;
  gap: 10px;
}

.images-list .img-holder {
  cursor: pointer;
  align-self: center;
  display: block;
  width: 100%;
}

.images-list .img-holder img{
  max-width: 100%;
  align-self: center;
}

.details {
  max-width: 1100px;
  margin: 5em auto 0 auto;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-bottom: 2em;
}

.details h2 {
  color: #933632;
}

.details .hire-instructions p{
  margin: 0.9em 0;
}

.details .pricing {
  background: #1c1c1c;
}

.details .pricing .hire-price {
  padding-bottom: 1em;
}

.details .pricing .hire-price h1, .details .pricing .hire-price h2{
  display: inline-block;
  font-family: 'Anonymous Pro', monospace;
}

.details .pricing .hire-price h1 {
  color: #e9e3ce;
  font-size: 2.4em;
}

.details .pricing .hire-price h2 {
  color: #858585;
  font-size: 1.6em;
}

.details .pricing h2.price-title {
  font-family: 'Karla', sans-serif;
  color: #e9ce81;
  font-size: 1.8em;
  font-weight: normal;
  padding: 0.8em 0 0.4em 0;
}

.details .pricing p {
  margin: 0.4em 0;
  color: #d9d9d9;
  font-size: 0.9em;
}

.map-info {
  margin: 0 auto;
  max-width: 1700px;
  background: #080808;
}

.map-info p {
  padding: 1em;
  margin: 0;
  color: #f1f1f1;
  text-align: center;
  font-size: 1.2em;
}

.map {
  margin: 0 auto 8em auto;
  max-width: 1700px;
  height: 300px;
}

.calendar-container {
  max-width: 1000px;
  margin: 1.5em auto;
  font-size: 1.2em;
}

.calendar-container h2 {
  color: #933632;
}

.calendar-container .controls{
  display: flex;
  justify-content: space-between;
  margin: 0.7em 0;
}

.calendar-container .controls p{
  font-family: 'Karla', sans-serif;
  margin: 0.5em;
  display: block;
  text-align: center;
}